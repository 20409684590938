/* Dots.css */
.dots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #3498db;
    border-radius: 50%;
    animation: dot-flashing 1s infinite alternate;
  }
  
  @keyframes dot-flashing {
    0% {
      background-color: #3498db;
    }
    50%,
    100% {
      background-color: #f3f3f3;
    }
  }
  
  .dot:nth-child(1) {
    animation-delay: -0.4s;
  }
  
  .dot:nth-child(2) {
    animation-delay: -0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0s;
  }
  