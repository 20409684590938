.activity-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9000;
}

.activity-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.activity-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.activity-modal-body {
  max-height: 70vh;
  overflow-y: auto;
}

.activity-close-btn {
  background: blue;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.activity-date-picker {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity-predefined-ranges {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
}

.activity-range-btn {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.activity-download-btn {
  padding: 15px 15px;
  margin: 0 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: large;
}

.activity-description {
  margin-bottom: 10px;
}
