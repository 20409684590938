.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

  }
  

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;  /* This ensures all children including `.amount-selection` are centered */
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
  
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

 
  
  .close-btn {
    background:#007bff;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .amount-selection {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;  /* Adds space between buttons and the input */
    margin-top: 20px;  /* Adds some spacing from any elements above */
  }
  
  .amount-selection  {
    width: 70px;
    height: 40px;
    text-align: center;
    margin: 0 10px;

  }
  .amount-input {
    width: 80px;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;

  }


  .amount-selection button + .amount-input {
    border-left: 1px solid #007bff; /* Adds a divider line */
  }
  
  .amount-selection .amount-input + button {
    border-left: 1px solid #007bff; /* Adds a divider line */
  }
  
  .amount-selection button {
    background-color: #007bff; /* Primary blue */
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    font-size: 16px;
    border-radius: 5px; /* Rounded corners for buttons */
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .amount-selection button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  
  .auto-refill {
    margin-top: 20px;
  }
  
  .saved-cards {
    margin-top: 20px;
    width: 40%;
  }
  
  .saved-cards ul {
    list-style-type: none;
    padding: 0;
  }
  
  .saved-cards li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .saved-cards li.selected {
    background-color: #f0f8ff;
    border-color: #00f;
  }
  
  .saved-cards .card-details {
    display: flex;
    align-items: center;
  }
  
  .saved-cards .delete-icon {
    cursor: pointer;
    color: #888;
  }
  
  .saved-cards .delete-icon:hover {
    color: #f00;
  }
  
  .saved-cards .card-details .fa {
    margin-right: 10px;
  }
  
  .use-saved-card-label {
    margin-top: 10px;
    font-weight: bold;
    color: #00f;
  }
  .stripe-checkout {
    width: 40%;
    margin: 0 auto; /* This centers the element horizontally */
  }

  .decrement-btn {
    font-size: 20px;
  }
  
  .increment-btn {
    font-size: 20px;
  }
  .auto-refill-info {
    padding: 10px;
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .modal-footer {
    display: flex;
    justify-content: right;
   
    margin-top: 20px;
    margin-left: auto;
  }
  
  .save-close-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-close-btn:hover {
    background-color: #0056b3;
  }
  

  .custom-toast-container .Toastify__toast-container {
    z-index: 19999; /* or any higher value */
 
  }


  
  .view-purchase-history-btn {
    display: block;
    margin: 10px auto;
    text-align: center;
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .view-purchase-history-btn:hover {
    background-color: #0056b3;
  }
  
  .purchase-history {
    margin-top: 20px;
    width: 100%;
  }
  .purchase-history ul {
    list-style-type: none;
    padding: 0;
  }
  
  .purchase-history li {
    margin-bottom: 10px;
  }
  


.history-header {
   
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .purchase-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .purchase-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .purchase-details p {
    margin: 0;
  }
  
  .download-pdf-btn .add-download-usage-btn{
    background-color: #007bff;
    color: white;
    border: none;
    margin-left: 8px !important;
    border-radius: 5px;
    cursor: pointer;

 
    align-items: center;
  }

  .add-download-usage-btn{
    background-color: #007bff;
    color: white;
    border: none;
    margin-left: 8px !important;
    border-radius: 5px;
    cursor: pointer;

 
    align-items: center;
  }
  
  .download-pdf-btn:hover {
    background-color: #0056b3;
  }


  .loading-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* White background with some transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300000; /* Make sure it covers everything else */
  }
  
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300000;
  }
  

  .session-usage-log {
    margin-top: 20px;
  }
  
  .session-usage-log h3 {
    margin-bottom: 10px;
  }
  
  .session-usage-log table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .session-usage-log th,
  .session-usage-log td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .session-usage-log th {
    background-color: #f2f2f2;
    text-align: left;
  }
  