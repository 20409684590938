/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  z-index: 299999;
}

.modal-content p {
  margin-bottom: 20px;
  width: 100%;
}

.modal-content button {
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:hover {
  background: #0056b3;
}
