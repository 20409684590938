.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 80%;
  height: 80%;
  max-width: none;
  overflow-y: auto;
  position: relative;
}

.form-group {
  text-align: left;
  margin: 20px 0;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group select,
.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.client-id-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}

.client-id-container input {
  flex: 1;
}

.generate-btn {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
}

.generate-btn:hover {
  background-color: #45a049;
}

.create-btn {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  margin-top: 20px;
  min-width: 100px;
  margin-left: 10px;
}

.create-btn:hover {
  background-color: #45a049;
}

.close-btn-start-voiceform {
  padding: 10px 20px !important;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f44336 !important;
  color: white;
  margin-top: 20px;
  margin-left: 10px;
  min-width: 100px;
}

.close-btn:hover {
  background-color: #d32f2f;
}

.success-message {
  color: green;
  margin-top: 20px;
  font-size: large;
  font-weight: bold;
}

.error-message {
  color: red;
  margin-top: 20px;
}

.client-id-with-button {
  display: flex;
  align-items: center; /* Aligns the button and input vertically */
}


.client-id-with-button input {
  flex: 1; /* Makes the input take up the remaining space */
  margin-right: 8px; /* Adds space between the input and the button */
}

.error-message-client-id {

  color: red;
  font-size: small !important;
}


/* Additions for rounded edges, dotted borders, and copy icon */

.session-id-box {
  color: black;
  border: 1px dotted #ddd;
  border-radius: 5px;
  padding-top: 20px;
  margin-top: 10px;
  position: relative;
  display: inline-block;
  width: 50%;
  text-align: center;
  font-weight: normal;
  position: relative;
  
}
.call-text {
padding: 10px;
  width: 80% !important;
  margin: auto;
}
.sessionId {
  font-size: xx-large;
  font-weight: bold;
  border: 2px dotted black; /* Adds a dotted border around the element */
  padding: 5px; /* Adds some padding inside the border for better visual appearance */
}

.copy-icon {
  position: absolute;
  top: -20px;
  right: 5px;
  cursor: pointer;
  z-index: 1 !important;
}

.copy-text {

  font-size:smaller;
  padding: 5px;
}

.copy-text.copied {
  color: green;
}

.copy-icon:hover::after {
  content: "Copy";
  position: absolute;
  top: -20px;
  right: 0;
  background: #333;
  color: #fff;
  padding: 3px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.client-id-text {

  color:grey;
}
/* Hide the form fields when success message is shown */
.form-group.hide-on-success {
  display: none;
}

/* Additional styling for the buttons at the bottom */


.button-group-start-voiceform {

  justify-content: space-between;
  margin-top: 20px;
}

.confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm-btn, .cancel-btn {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
}


/* Add these to your StartNewVoiceForm.css */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dot {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background-color: #333;
  border-radius: 50%;
  animation: dot-blink 1s infinite alternate;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.success-message table {
  width: 50%; /* Set width to 60% or adjust between 50% to 60% as needed */
  margin: 20px auto; /* Top and bottom margins are 20px, left and right are auto for centering */
  border-collapse: collapse; /* Ensures borders between cells are merged */
  text-align: left; /* Aligns text to the left, adjust as needed */
  color: black;
  font-weight: normal;

}
.success-message th,
.success-message td {
  border: 1px solid #ccc; /* Adds border with light grey color */
  padding: 8px; /* Adds padding inside cells */
  text-align: center; /* Centers text in cells */
}

.success-message th {
  background-color: #f4f4f4; /* Light grey background for headers */
  color: #333; /* Dark grey text color for headers */
}


/* Tooltip container */
.tooltip-icon {
  margin-left: 8px;
  cursor: pointer;
  position: relative;
}

/* Tooltip text */
.tooltip-text {
  font-size: large;
  visibility: hidden;
  width: 220px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 10000;

 left: -10px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}
/* Show the tooltip text when hovering over the tooltip container */
.tool-tip-title:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Style for the "How to use" text */
.tool-tip-title {
  text-align: left;
  top: 5px;
  left: 5px;
  font-size: medium;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  position: absolute; /* Position relative to enable absolute positioning for the tooltip */
}


/* Auth divider styling */
.auth-divider-form {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the lines and text */

  width: 100%; /* Ensure it spans the full width of the tooltip */

}


.auth-divider-form .line-form {

  height: 1px;
  background-color: #ccc;
  align-items: center;
  width: 9%; /* Set a fixed width for the lines */
  margin-left: 2%;
  margin-right: 2%;
}

.auth-divider-form span {
  padding: 0 10px;
  align-items: center;
 
  z-index: 1;
  color: #666;
}

.or-text-form {

  position: relative;
color: white;
opacity: 1;

font-weight:normal;
}




.rounded-table {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;

}

.rounded-table th,
.rounded-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.rounded-table th:first-child,
.rounded-table td:first-child {
  border-left: none;
}

.rounded-table th:last-child,
.rounded-table td:last-child {
  border-right: none;
}

.rounded-table thead th {
  background-color: #f4f4f4;
  color: #333;
}

.rounded-table tbody tr:last-child td {
  border-bottom: none;
}
