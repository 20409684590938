/* App.css - General layout styles, avoid specific sidebar styles here */


.App {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;

}

/* Top-right menu styles */
.top-right-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  z-index: 100;
}

.logo {
  width: 100px; /* Increase the logo size */
  margin-right: 10px;
height: 90%;
}


.dropdown-menu {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1300;
  border-radius: 4px;
  overflow: hidden;

}

.dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

.dropdown-item:hover {
  background-color: #f4f4f4;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.no-link {

  color: black;
  font-style: normal;
  text-decoration: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8); /* Dark background for the overlay */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highlight {
  position: absolute;
  padding: 10px;
  border: 2px solid red; /* Highlight with a red border */
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white */
  z-index: 1001;
}


.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.auth-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.auth-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-section input {
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.auth-section button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.auth-section button:hover {
  background-color: #0056b3;
}

.auth-item {
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
}

.toggle-auth {
  margin-top: 10px;
}

.auth-section {
  position: relative;
  padding: 20px;
 
}

.auth-divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
  position: relative;
  width: 30%;
}

.auth-divider .line {
  flex: 1;
  height: 1px;
  background-color: #ccc;
}

.auth-divider span {
  padding: 0 10px;
 
  z-index: 1;
  color: #666;
}




.spinner-overlay {
  position: fixed;       /* Positioning fixed to make it cover all content */
  top: 0;
  left: 0;
  width: 100%;           /* Full width */
  height: 100%;          /* Full height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999000;         /* High z-index to overlay on top of other content */
  display: flex;         /* Flexbox to center the spinner */
  justify-content: center; /* Center horizontally */
  align-items: center;   /* Center vertically */
}
