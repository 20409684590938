.lp-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .lp-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    z-index: 299999;
    width: 90%;
    max-width: 600px;
    position: relative;
    max-height: 70%; /* Set the max height of the modal */
    overflow-y: auto; /* Enable vertical scrollbar if content is too tall */
  }
  
  .lp-modal-close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 25px;
    color: white;
    padding: 5px;
    background: #007bff;
    border-radius: 5px;
  }
  
  .lp-tabs {
    display: flex;
    border-bottom: 1px solid #ccc;
  }
  
  .lp-tab {
    flex-grow: 1;
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-bottom: none;
    background: lightgray;
    transition: background-color 0.3s ease;
  }
  
  .lp-tab.active {
    background: white;
    border-top: 2px solid #007bff;
  }
  
  .lp-input-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .lp-input-field {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .lp-input-label {
    margin-bottom: 5px;
    text-align: left;
    width: 100%;
  }
  
  .lp-submit-button, .lp-close-button {
    padding: 10px 20px;
    margin-bottom: 15px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .lp-submit-button:hover, .lp-close-button:hover {
    background: #0056b3;
  }
  

  .lp-scroll-to-bottom {
    position: sticky;
    margin-left: auto; /* Right within the modal */
    bottom: 10px; /* Bottom within the modal */
    cursor: pointer;
    background: #007bff;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3000; /* Ensure it's above modal content */
  }
  

  .success-message h4{

color: black;
  }