.StripeElement {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .save-card,
  .auto-refill {
    margin-bottom: 10px;
  }
  
  button {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  

  .pay-button {
background-color: blue;
    color: white;
width: 100%;
    padding: 15px;
    font-size: large;
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .amount-input {
    width: 60px;
    text-align: center;
    margin: 0 10px;
  }
  

  .message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  

 