.confirmation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .confirmation-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .confirmation-modal-content p {
    margin-bottom: 20px;
  }
  
  .confirmation-modal-content input {
    padding: 10px;
    margin-bottom: 20px;
    width: 80%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .confirmation-modal-content .button-container {
    display: flex;
    justify-content: center;
  }
  
  .confirmation-modal-content button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    height: 40px; /* Explicit height to ensure same size */
  }
  
  .confirmation-modal-content .confirm-btn {
    background: #28a745;
  }
  
  .confirmation-modal-content .confirm-btn:hover {
    background: #218838;
  }
  
  .confirmation-modal-content .close-btn {
    background: #dc3545;
  }
  
  .confirmation-modal-content .close-btn:hover {
    background: #c82333;
  }
  