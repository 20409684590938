.help-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-help-page {
  text-align: center;
  width: 100%;
  margin: 20px 0;
}

.logo-help-page {
  width: 200px; /* Adjust as necessary */
}

.container-help-page {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.sidebar-help-page {
  flex-basis: 240px;
  min-width: 25%;
  background-color: #f1f1f1;
  padding: 20px;
  min-height: 100vh;
  position: sticky;
  top: 0;
  transition: transform 0.3s ease;
}

.sidebar-help-page.open-help-page {
  transform: translateX(0);
}

.main-content-help-page {
  flex-grow: 1;
  padding: 20px;
  padding-top: 0px;
}

.content-section-help-page {
  margin-top: 0px;
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100% !important;
}

.menu-icon-help-page {
  display: none;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1001;
  background: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

.sidebar-help-page ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
}

.sidebar-help-page li {
  padding: 10px;
  cursor: pointer;
  transition: font-size 0.3s ease;
}

.sidebar-help-page li.selected-help-page {
  font-size: 20px; /* Larger font for selected item */
  font-weight: bold;
  color: #007bff; /* Change color to highlight selected item */
}

.left-auto-help-page {
  margin: auto;
  text-align: left;
  padding-left: 20px;
  width: 80%;
}

.left-auto-first-help-page {
  padding-top: 30px;
}

.helpImage1-help-page {
  padding-top: 10px;
  width: 200px;
  margin: auto;
  padding-bottom: 10px;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .sidebar-help-page {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%); /* Hide sidebar off-screen */
    width: 240px;
  }

  .main-content-help-page {
    padding: 0px;
  }

  .left-auto-help-page {
    padding-left: 2px;
  }

  .left-auto-first-help-page {
    width: 80%;
    margin: auto;
    padding-bottom: 5px;
  }

  .content-section-help-page {
    margin-top: 40px !important;
    padding: 10px;
    margin: auto;
    overflow: hidden !important;
  }

  .menu-icon-help-page {
    display: block;
  }
}
