/* ConfirmationModal.css */
.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300000; 
}


.confirmation-modal-overlay-hippa {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75); /* Darker background for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300000; /* High z-index to ensure it is on top */
}
.confirmation-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 90%;
  z-index: 10000; 

}


.confirmation-modal-content-hippa {


  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 90%;
  max-width: 600px; /* Max width for better control on larger screens */
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Soft shadow for 3D effect */
  z-index: 10000; /* Ensuring content is also elevated */
}




.confirmation-modal-content p {
  margin-bottom: 20px;
}

.confirmation-modal-content button {
  padding: 10px 20px;
  margin: 0 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirmation-modal-content button:hover {
  background: #0056b3;
}
