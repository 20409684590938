/* CreateFormModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 80%;
  height: 80%;
  max-width: none;
  overflow-y: auto;
  position: relative;
}


.form-name-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.form-name-input {
  width: 70%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.saved-form-name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: bold;
}

.welcome-message-container {
  text-align: left;
  margin: 20px 0;
  position: relative;
}

.welcome-message-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.saved-welcome-message {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  white-space: pre-wrap; /* To keep the new lines */
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 0px;
  z-index: 2000;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 420px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
top: 10px;

  left: 0%;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.button-group {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.button-group button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px;
}

.button-group button:hover {
  background-color: #ddd;
}

.manual-input-section {
  text-align: left;
  margin-top: 20px;
}

.question-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.question-input input,
.question-input select {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 5px 0;
}

.conditional-questions {
  margin-top: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.save-btn {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  margin-top: 20px;
}

.save-btn:hover {
  background-color: #45a049;
}

.auto-scroll {

  padding-left: 20px;
  margin-bottom: 15px;
}
.bottom-buttons {

  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 20px;
  gap: 10px;
  z-index: 300000;

}



.save-complete-btn {
  padding: 10px 20px;

  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  margin: 0 20px;
}

.save-complete-btn:hover {
  background-color: #45a049;
}

.close-btn {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f44336;
  color: white;

}

.close-btn:hover {
  background-color: #d32f2f;
}

.question-icons {
  display: flex;
  gap: 10px;
}

.edit-icon,
.delete-icon {
  
  cursor: pointer;
}

.conditional-summary {
  font-size: 14px;
  color: #555;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.followUpQuestion {
  width: 80%;
}


.welcome-message-container {
  position: relative;
  margin-bottom: 20px;
}

.welcomeMessageInto {

font-weight: bold;
font-size:small;
color: rgb(11, 10, 10);

}

.welcome-message-editable-container {
  position: relative;
}

.welcome-message-input {
  width: 80%;
  height: 100px;
  padding-right: 120px; /* To make space for the fixed message */
  font-family: inherit; /* Ensure the font is consistent */
  padding-bottom: 40px;
}

.fixed-message {
  position: absolute;
  left: 1%;
  bottom: 10px;
  font-size: 14px;
  color: black;
  pointer-events: none;
  background: white;
  padding: 0px;
  font-weight: bold ;
}

.welcome-message-dropdown {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.welcome-message-header {
  padding: 10px;
  background: #f9f9f9;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-family: inherit; /* Ensure the font is consistent */
}

.welcome-message-content {
  padding: 10px;
  background: white;
  font-size: 16px;
  font-family: inherit; /* Ensure the font is consistent */
}



.conclusion-message-container {
  text-align: left;
  margin: 20px 0;
  position: relative;
}

.conclusion-message-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-family: inherit;
}

.conclusion-message-dropdown {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.conclusion-message-header {
  padding: 10px;
  background: #f9f9f9;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-family: inherit; /* Ensure the font is consistent */
}

.conclusion-message-content {
  padding: 10px;
  background: white;
  font-size: 16px;
  font-family: inherit; /* Ensure the font is consistent */
}

.welcome-message-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 20px 0;
}



.welcome-message-dropdown {
  width: 100%; /* Ensure the dropdown takes full width */
  margin-top: 20px; /* Add margin to separate it from the grid */
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.welcome-message-header {
  padding: 10px;
  background: #f9f9f9;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-family: inherit; /* Ensure the font is consistent */
}

.welcome-message-content {
  padding: 10px;
  background: white;
  font-size: 16px;
  font-family: inherit; /* Ensure the font is consistent */
}

.info-box {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1px;
  margin: 0px 0;
}

.info-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.info-icon {
  margin-right: 8px;
}

.welcome-message-editable-container {
  position: relative;
}

.welcome-message-input {
  width: 80%;
  height: 100px;
  padding-right: 120px; /* To make space for the fixed message */
  font-family: inherit; /* Ensure the font is consistent */
  padding-bottom: 40px;
}

.fixed-message {
  position: absolute;
  left: 1%;
  bottom: 10px;
  font-size: 14px;
  color: black;
  pointer-events: none;
  background: white;
  padding: 0px;
  font-weight: bold;
}

.edit-icon-container {
  cursor: pointer;
}


.remove-question {

  margin-left: 5px;
}
/* Mobile-specific styles */
@media (max-width: 768px) {


}



.left {

  text-align: left;
}




.numbers {

  font-weight: bold;
  padding-right: 5px;
  font-size: large;
}


.transcript-handling-container {
  text-align: left;
  margin: 20px 0;
  margin-bottom: 0;

}

.transcript-options {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;


}

.transcript-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  
}

.transcript-option p {
  margin-top: 5px;

}

.transcript-option img.option-icon {
  width: 20px;
  height: 20px;
}

.option-icon {

  font-size: 20px;
}
.transcript-option.disabled {
  opacity: 0.5;
  pointer-events: none;
}


.top {
  margin-top: 0px;
}

