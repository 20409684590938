.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px; /* Adjusted to fit in the send button */
  height: 24px; /* Adjusted to fit in the send button */
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
