/* Styles related to authentication section for Login */

.login-auth-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  position: relative;
}

.login-header {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  padding: 20px 0;
}

.login-header h1 {
  margin: 0;
  font-size: 24px;
}


.login-logo {
  height: 40px;
  cursor: pointer;
}

.login-auth-box {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-auth-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: auto;
}

.login-auth-section input {
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-auth-section button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.login-auth-section button:hover {
  background-color: #0056b3;
}


.login-auth-item {
  cursor: pointer;
  padding: 10px;
  background: #f0f0f0;
  margin: 10px 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-auth-item:hover {
  background: #e0e0e0;
}

.login-toggle-auth {
  margin-top: 10px;
}

.login-auth-divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
  position: relative;
  width: 80%;
  margin: 20px auto;
}

.login-auth-divider .line {
  flex: 1;
  height: 1px;
  background-color: #ccc;
}

.login-auth-divider span {
  padding: 0 10px;
  z-index: 1;
  color: #666;
  background-color: white;
}

.error-message {
  color: red;
  margin-top: 10px;
}


.send-email-button {

  margin-bottom: 5px;
}