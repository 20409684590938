.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .inputBox {

    width: 70%;
  }
  
  .validation-error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  



  .a-scroll-to-bottom {
    position: sticky;
    margin-left: auto; /* Right within the modal */
    bottom: 10px; /* Bottom within the modal */
    cursor: pointer;
    background: #007bff;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3000; /* Ensure it's above modal content */
  }



  .a-bottom {
    position: sticky;
    margin: auto; /* Right within the modal */

    bottom: -20px; /* Bottom within the modal */
    cursor: pointer;
    color: black;
 background-color: white;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3000; /* Ensure it's above modal content */
  }

  .agree-checkbox {

    padding-right: 10px;
  }

  .toc ul {

    list-style-type: none;
  }


  .validation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .validation-modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
  
  .validation-modal-content p {
    margin: 0;
    margin-bottom: 20px;
  }
  
  .validation-modal-content button {
    cursor: pointer;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
  }
  