/* Overlay that covers the entire viewport */
.account-settings-modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Container for modal content */
  .account-modal-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: auto;
    max-width: 500px;
    min-width: 300px;
 
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  /* Inputs within the modal */
  .account-settings-modal-input {
    width: calc(100% - 40px);
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  /* Generic button styling in the modal */
  .account-settings-modal-btn, .account-settings-modal-close-btn {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect for buttons */
  .account-settings-modal-btn:hover, .account-settings-modal-close-btn:hover {
    background-color: #0056b3;
  }
  
  /* Specific styles for a close or cancel button */
  .account-settings-modal-close-btn {
    background-color: #007bff; /* Red color for close or cancel actions */
  }
  
  .account-settings-modal-close-btn:hover {
    background-color: #c33;
  }
  



    .account-close-btn {
  
        margin-left: auto;
        background:#007bff;
        border: none;
        font-size: .75rem;
        cursor: pointer;
      
  }