/* Quick replies are positioned at the bottom */
.quick-replies {
    display: flex;
    justify-content: center; /* Centers the buttons */
    padding: 10px;
    background-color: #fff;
    position: fixed;
    bottom: 60px; /* Position above the chat-form */
    width: 100%;
    left: 0;
    right: 0;
  }
  
  .quick-replies button {
    margin: 5px;
    padding: 10px 20px;
    background-color: lightgray;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .quick-replies button:hover {
    background-color: gray;
  }
  
  /* Responsive styles for mobile devices */
  @media (max-width: 768px) {
    .quick-replies {
      flex-direction: column; /* Stack buttons vertically */
      align-items: center; /* Center-align buttons */
    }
  
    .quick-replies button {
      width: 90%; /* Make buttons take most of the screen width */
      padding: 8px 15px; /* Smaller padding */
      font-size: 14px; /* Smaller font size */
      text-align: center; /* Center text */
    }
  }
  
  @media (min-width: 769px) {
    .quick-replies.sidebar-open {
      padding-left: 100px; /* Adjust width when sidebar is open */
    }
  }
  