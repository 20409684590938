/* ChatInput.css */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure it takes the full viewport height */
  width: 100%;
  margin: auto;
  padding-top: 10px;
  overflow: hidden; /* Ensure no overflow */
}

.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; /* Add space below the toggle button */
}

.toggle-btn {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #007bff; /* Button color */
  display: flex;
  align-items: center;
  gap: 5px;
}

.chat-content {
  flex-grow: 1;

  flex-direction: column;

  max-width: 800px;
  margin: 0 auto;
 /* Ensure it takes the full height minus padding */
  overflow: hidden;
}

.chat-log {
  overflow-y: auto;
  flex-grow: 1; /* Allow it to grow to fill available space */
  margin-bottom: 10px; /* Reduce bottom margin */
  max-height: calc(100vh - 200px); /* Adjust height */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chat-log-message {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0; /* Space between messages */
  word-wrap: break-word;
  align-self: flex-start;
  position: relative;
  max-width: 70%; /* Adjust this value to control message bubble width */
  width: fit-content;
}

.timestamp {
  font-size: 12px;
  color: #555;
  padding: 2px 5px;
  border-radius: 5px;
  position: absolute;
  top: -15px; /* Adjust this value to control the space above the message */
  left: 0px; /* Adjust this value to align with the message content */
  width: 150px; /* Fixed width for the timestamp */
  white-space: nowrap; /* Prevent wrapping */
  display: none;
  font-size: x-small;
}

.chat-log-message:hover .timestamp {
  display: block;
}

.message {
  margin-top: 10px;
  white-space: pre-line; /* This ensures that line breaks are handled */
}

/* Chat form positioned at the very bottom */
.chat-form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.input-text {
  flex-grow: 1;
  max-width: 800px;
  margin: 0 10px;
  padding: 10px;
  font-size: 18px;
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.input-text:focus {
  border-color: #0056b3;
}
.sessionId-container {
  position: relative; /* Needed for absolute positioning of the child */
}

.sessionId-2 {
  position: relative;
  font-size: xx-large;
  font-weight: bold;
  border: 2px dotted black; /* Adds a dotted border around the element */
  padding: 5px; /* Adds some padding inside the border for better visual appearance */
  display: inline-block; /* Allows the container to fit the content width */
  pointer-events: auto; /* Enables click events on the session ID */
}

.copy-icon {
  z-index: 10000;
  position: absolute;
  top: -10px; /* Positions slightly above the container */
  right: -40px; /* Positions slightly to the right of the container */
  cursor: pointer !important; /* Changes the cursor to indicate it's clickable */
  background: white !important; /* Optional: adds background */
  border: none;
  padding: 5px !important; /* Padding around the text and icon */
  pointer-events: auto; /* Ensure it's clickable */
  color: black !important;
}

.attach-btn,
.send-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: black;
}

.send-btn:hover {
  color: #555;
}

/* Adjust layout when sidebar is open */
@media (min-width: 769px) {
  .chat-form.sidebar-open {
    padding-left: 100px;
  }

  .message-window.sidebar-open {
    padding-left: 100px;
  }

  .prompt-message.sidebar-open {
    padding-left: 100px;
  }

  .quick-replies.sidebar-open {
    padding-left: 100px;
  }

  .chat-log.sidebar-open {
    padding-left: 100px;
  }

  .chat-container.sidebar-open {
    padding-left: 100px;
  }
  .message {
    width: 100%;
  }
}

/* Mobile-specific styles */

@media (max-width: 1000px) {

  .features-list {
display: block;
margin-left: 100px;

  }


}
@media (max-width: 1000px) {
  .chat-form {
    width: 95%;
    padding: 5px;
  }


.button-container {
  display: flex;
  gap: 5px; /* Adjust the gap between buttons as needed */
  width: 70% !important;



}



  .content-section-chat-input {
    width: 80% !important; 


  }

  .chat-log {
    padding-bottom: 10px;
    margin-bottom: 250px;
    padding-left: 10px;
  }

  .input-text {
    font-size: 16px;
    max-width: 100%;
  }

  .attach-btn,
  .send-btn {
    font-size: 18px;
  }

  .message-window,
  .message-window.sidebar-open {
    display: none;
    width: 100%;
    margin: auto;
    padding: 0px;
    padding-top: 210px;
    text-align: center;
  }

  .chat-container {
    width: 100%;
    padding-top: 30px;
  }

  .prompt-message {
    display: none;
  }
}

.message-window {
  text-align: center;
  padding: 10px;
  font-size: 18px;
  color: #666;
  margin-bottom: 10px;
  margin: auto;
  padding-top: 10px;
  width: 60%;
}

.prompt-message {
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 80%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.standard-mode-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 60px;

  overflow-y: auto !important; /* Ensures content scrolls if too large */
  flex-grow: 1; /* Allow it to grow to fill available space */
  height: calc(100vh - 200px); /* Adjust to ensure it fits in the viewport */
}

.content-box {
  background-color: #007bff;
  color: white;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  cursor: pointer;
  width: 70%;
  height: 50%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;

}

.content-section-chat-input {
  width: 70% !important;
  margin-top: 20px;
  overflow-y: auto; /* Add overflow to ensure scrolling */
  overflow: visible; 
}

.subdir {
  margin: 10px 0;
}

.forms-table,
.subfolders-table {
  width: 100%;
  border-collapse: collapse;
}

.forms-table th,
.forms-table td,
.subfolders-table th,
.subfolders-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.forms-table th,
.subfolders-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.forms-table tr:hover
{
  background-color: #f1f1f1;
}

.edit-icon,
.delete-icon,
.view-icon {
  cursor: pointer;
  margin-right: 8px;
}

.inner-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  border-radius: 10px;
  overflow: visible;
  border: 1px solid #e0e0e0; /* Add this to maintain border color */
  margin-bottom: 70px;
}

.inner-table th,
.inner-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.inner-table th {
  background-color: #f2f2f2;
  text-align: left;
}



.inner-table td:hover {
  background-color: #f1f1f1;
}



.form-header {
  cursor: pointer;
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  margin-bottom: 5px;
}

.form-header:hover {
  background-color: #ddd;
}

.form-body {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  margin-bottom: 10px;
}

.form-icons {
  float: right;
}

.inner-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  table-layout: fixed;  /* Helps enforce column widths */

}



.inner-table th,
.inner-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;

}

.inner-table th:nth-child(2), .inner-table td:nth-child(2) {
  width: 20%; /* Adjust this value to fit your design */
}


.form-list {
  position: relative; /* Establish a stacking context */
  z-index: 1; /* Ensure it's above standard content */
}



.dropdown-menu {
display: block; /* Or 'none' initially, and toggle with JS */
position: absolute;
background-color: #f9f9f9;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 100; /* Higher than surrounding elements */
}

.dropdown-content a {
color: black;
padding: 12px 16px;
text-decoration: none;
display: block;
}
.active-row {
background-color: #f0f0f0; /* Light grey background for active rows */
}

.accordion-content {
display: none;
}

.accordion-content td {
background-color: #e9ecef; /* Lighter grey for expanded content */
}

.active-row + .accordion-content {
display: table-row; /* Show the accordion content when the row is active */
}


.toggle-icon {
margin-left: 10px;
cursor: pointer;
}

.table {
table-layout: fixed;
width: 100%;
}

.call-text {

  color: black;
}
.sort-buttons {
display: flex;
justify-content: flex-start;
gap: 10px;
margin-bottom: 10px;
}

.sort-buttons button {
background-color: #4e6681;
border: none;
color: white;
padding: 8px 12px;
font-size: 14px;
border-radius: 4px;
cursor: pointer;
}

.sort-buttons button:hover {
background-color: #0056b3;
}

.status-part {
font-size: small;
}
/* Existing CSS remains unchanged */

.archive-table, .subfolders-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
  overflow: hidden; /* Ensures rounded corners are visible */
  border: 1px solid #e0e0e0; /* Add this to maintain border color */
}

.archive-table th,
.archive-table td,
.subfolders-table th,
.subfolders-table td {
  border: 1px solid #e0e0e0; /* Light border color */
  padding: 10px;
  text-align: left;
}

.archive-table th,
.subfolders-table th {

}

.archive-table td,
.subfolders-table td {

}

.archive-table tbody tr:nth-child(odd),
.subfolders-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.archive-table tbody tr:nth-child(even),
.subfolders-table tbody tr:nth-child(even) {
  background-color: #fff;
}

@media (max-width: 768px) {
  .archive-table th,
  .archive-table td,
  .subfolders-table th,
  .subfolders-table td {
    padding: 5px;
  }


  .mobile {

    font-size: x-small;
  }

  .dropbtn {
margin-left: -20px;
 font-size: xx-small;
 padding: 0px !important;
  }
  .sort-buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .sort-buttons button {
    margin-bottom: 5px;
    width: 100%;
  }
}

.button-container {
  display: flex;
  gap: 10px; /* Adjust the gap between buttons as needed */
  width: 50%;
  margin: auto;
}

.archive-button {

  margin-top: 10px;
  padding: 2px;
}
.button-container .content-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.button-container .content-box:hover {
  opacity: 0.9;
}

.archive-table .pale-color {
  background-color: palegreen !important;
}
