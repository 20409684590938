/* Sidebar container styles */
.sidebar {
  width: 250px; /* Adjust width as needed */
  height: 100vh; /* Full viewport height */
  background-color: #f4f4f4; /* Light grey background */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Adds shadow to the right side */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  padding-top: 50px; /* Space at the top */
  transition: all 0.3s ease;
  position: fixed; /* Fixed positioning to remain visible */
  left: 0; /* Align to the left edge */
  z-index: 1000; /* Ensure it appears above other content */
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  transform: none !important;
  
}

.expand-toggle {

  margin-left: auto;
}

.expand-toggle.blue-box {
  background-color:#007bff; /* or any shade of blue you prefer */
  color: white; /* white text color for contrast */
  border: none; /* optional: removes the border */
  padding: 10px; /* padding for the button */
  border-radius: 5px; /* rounded corners */
}

.expand-toggle.red-box {
  background-color: #007bff; /* changes to red when expanded */
  color: white; /* maintains white text for contrast */
  border: none; /* optional: removes the border */
  padding: 10px; /* consistent padding */
  border-radius: 5px; /* rounded corners */
}

.sidebar.modal-style {
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  max-width: 600px;
  background-color: #f4f4f4; /* Opaque background for the modal */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  z-index: 950; /* Higher than the overlay */
  overflow-y:scroll;
  transition: all 0.3s ease;
}




/* Optional: Customize scrollbar appearance (for WebKit browsers like Chrome, Safari) */
.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.profile {
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide any overflow text */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflow text */
  max-width: 100%; /* Ensure it fits within the container */
  padding-bottom: 10px;
}

.sidebar.active {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
  display: block;
  padding-top: 0px; /* Space at the top */
}

.sidebar.inactive {
  transition: transform 0.3s ease-in-out;
  display: none;
}

.toggle-sidebar-btn {
  position: fixed; /* Keep the button fixed */
  top: 20px; /* Distance from the top of the viewport */
  left: 5px; /* Distance from the left edge of the viewport */
  z-index: 1200; /* Higher z-index to ensure it's above the sidebar */
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px; /* Adjust size as needed */
  color: #333; /* Button color */
}



/* Tagline styles */
.tagline {
  font-size: 16px;
  color: #333; /* Dark grey text */
  text-align: center; /* Center-align text */
  margin-bottom: 30px; /* Space below the tagline */
}

.tagline-expanded {

  margin-bottom: 2px;
}

/* Menu list styles */
.menu {
  list-style: none; /* Remove bullet points */
  width: 100%; /* Full width of the sidebar */
  padding: 0; /* Remove padding */
}

/* Menu item styles */
.menu li {
  font-size: medium;
  list-style: none;
  text-align: center; /* Center-align text */
  border-bottom: 1px solid #ddd; /* Light grey border between items */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.menu ul {
  padding-inline-start: 10px;
}

.menu li:hover {
  background-color: #e9e9e9; /* Light grey background on hover */
}

.menu li:last-child {
  border-bottom: none; /* Remove bottom border for the last item */
}

.create-main-btn {
  background-color: #28a745; /* Blue background color */
  color: white; /* White text color */
  font-size: 16px; /* Font size */
  font-weight: bold; /* Bold text */
  padding: 10px 10px; /* Padding */
  margin: 20px auto; /* Center the button */
  display: block; /* Make it a block element to use margin auto */
  text-align: center; /* Center text inside the button */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
}

.create-main-btn:hover {
  background-color: #218838; /* Darker blue on hover */
}

.voice-selection {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align items */
  margin-top: 10px; /* Space above the voice selection */
  padding-bottom: 10px;
}

.voice-selection select {
  margin-bottom: 10px; /* Space below the dropdown */
}

.play-sample-btn-sidebar {
  background-color: #28a745; /* Green background color */
  color: white; /* White text color */
  font-size: 14px; /* Font size */
  padding: 5px 10px; /* Padding */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
}

.play-sample-btn:hover {
  background-color: #218838; /* Darker green on hover */
}

.auth-section {
  width: 100%;
}

.auth-item {
  margin-bottom: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.toggle-auth {
  margin-top: 10px;
}

.toggle-auth a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.scroll-to-bottom {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensures the button is above other content */
}

.scroll-to-bottom:hover {
  background-color: #0056b3;
}


.scroll-to-bottom-expanded {
display: none;
}

.credits-available {
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column; /* Arrange items in a column */
  align-items: center; /* Align items to the start of the container */
}

.sign-out {

  padding: 20px;
}

.profile-email {

  padding-bottom: 10px;
}

.credits-label {
  padding-top: 10px;
  font-size: 1em; /* Base font size */
  white-space: nowrap; /* Prevent line break */
  overflow: hidden;
  text-overflow: ellipsis; /* Handle overflow with ellipsis */
  flex-shrink: 0; /* Prevent shrinking */
  margin-right: 5px; /* Space between label and value */
}

.credits-value {
  font-weight: bold;
  font-size: 1em; /* Base font size */
  flex-shrink: 0; /* Prevent shrinking */
}


/* This CSS targets screens smaller than 768 pixels */
@media (max-width: 768px) {
  .expand-toggle {
    display: none; /* Hides the expand toggle button on small screens */
  }

  
}


@media (max-width: 600px) {

 
  .credits-label {
    font-size: 0.8em; /* Smaller font size for small screens */
  }
  .credits-value {
    font-size: 0.8em; /* Smaller font size for small screens */
  }
}

.accessTitle {
  font-size: small;
  font-weight: 500;
}

/* Sidebar.css */

/* Add styles for the add credits button */
.add-credits-btn {
  color: #007bff;
  font-weight: bold;
  margin-left: 0px;
  cursor: pointer;
}

.add-credits-btn:hover {
  text-decoration: underline;
}

.credits-negative {
  color: red;
}

.sub-users li {
  display: flex;
  flex-direction: column;
  font-size: small;
  text-align: left; /* Align text to the left */

}

.sub-users ul {
  list-style-type: none;
  padding: 0;
padding-bottom: 20px;
}

.sub-users .sub-user-email {
  margin-bottom: 5px; /* Space between email and buttons */
  font-weight: bold;
}

.sub-users .sub-user-buttons {

  justify-content: space-between;
  width: 80%; /* Ensure buttons take the full width available */
  padding-top: 5px;
  padding-bottom: 15px;


}

.add-sub-user {

  text-align: left;
}

.sub-users button {
  min-width: 30%;
  display: inline-block;
  font-size: smaller;
  padding: 2px;

}


.custom-toast-container {
  position: relative;
  top: 1rem;
  right: 1rem;
  width: auto;
  z-index: 9999;
}
.manage-sub-users-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.manage-sub-users-header h3 {
  margin: 0;
  padding-right: 0.5rem;
}

.manage-sub-users-header .fa-caret-down,
.manage-sub-users-header .fa-caret-right {
  font-size: 1rem;
}




