.signup-auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.signup-auth-box {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.signup-auth-section {
  margin-bottom: 20px;
}

.signup-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.signup-sub-heading {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
}

.signup-auth-section input {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.signup-auth-section button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.signup-auth-section button:hover {
  background-color: #0056b3;
}

.signup-auth-divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.signup-auth-divider .line {
  flex: 1;
  height: 1px;
  background: #ccc;
}

.signup-auth-divider span {
  margin: 0 10px;
  color: #666;
}

.signup-auth-item {
  cursor: pointer;
  padding: 10px;
  background: #f0f0f0;
  margin: 10px 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-auth-item:hover {
  background: #e0e0e0;
}

.signup-price-info-section {
  margin-top: 20px;
  text-align: left;
}

.signup-image-placeholder {
  margin-top: 20px;
}

.signup-image {
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
}
